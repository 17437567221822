import React from 'react'
import { navigate } from '@reach/router'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import * as Accessa from 'accessa'
import Viewport from 'accessa/lib/Utils/Viewport'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'
import {
  DarkMode,
  FunFacts,
  withGlobals,
} from '../components'

const useStyles = makeStyles(() => ({
  billboard: {
    '& header > div': {
      minHeight: '350px',
    },
  },
  bgImg: {
    '& img': {
      backgroundColor: 'rgb(176, 195, 173)',
    },
  },
}))

const Index = ({ allContentfulIndex }) => {
  const contentfulIndex = get(
    allContentfulIndex,
    'nodes',
    []
  )[0]
  const cls = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <Box className={cls.billboard}>
        <Viewport style={{ height: '100vh' }}>
          <Accessa.Headers.Billboard
            title={get(contentfulIndex, 'hero.title')}
            subtitle={get(contentfulIndex, 'hero.subtitle')}
            fluid={get(contentfulIndex, 'hero.image.fluid')}
            style={{
              padding: '14rem 0',
            }}
          >
            <Accessa.Galleries.Lightbox
              lists={[
                {
                  'video': get(
                    contentfulIndex,
                    'hero.video'
                  ),
                  'title': 'Canada Rubber Group Promo',
                },
              ]}
            />
          </Accessa.Headers.Billboard>
        </Viewport>
      </Box>
      <DarkMode>
        <Box
          bgcolor="background.default"
          color="text.primary"
          pt={6}
          pb={10}
        >
          <Accessa.Sections.Overview
            align="center"
            description={get(
              contentfulIndex,
              'summary.description.description'
            )}
            maxWidth="md"
            style={{
              marginBottom: '2rem',
            }}
            title={get(contentfulIndex, 'summary.title')}
          >
            <Container maxWidth="lg" className={cls.bgImg}>
              <Accessa.Features.Field
                fill
                lists={get(
                  contentfulIndex,
                  'summary.nested',
                  []
                ).map((item) => ({
                  title: get(item, 'hero.title'),
                  description: get(item, 'hero.subtitle'),
                  fluid: get(item, 'hero.image.fluid'),
                  onClick: () =>
                    navigate(item.relativePath),

                  style: {
                    height: 270,
                    width: '100%',
                  },
                }))}
              />
            </Container>
          </Accessa.Sections.Overview>
        </Box>
      </DarkMode>
      <FunFacts {...contentfulIndex}>
        <Box
          mt={2}
          position="relative"
          px={2}
          py={8}
          textAlign="center"
        >
          <Accessa.Galleries.Authorities
            title={t('titles:certificates')}
            logos={get(contentfulIndex, 'logos').map(
              ({
                title = 'CRG Certification',
                description = '/',
                ...rest
              }) => ({
                alt: title,
                to: description,
                ...rest,
              })
            )}
          />
        </Box>
      </FunFacts>
    </>
  )
}

Index.propTypes = {
  // eslint-disable-next-line
  allContentfulIndex: PropTypes.object.isRequired,
}

export const query = graphql`
  query getContentfulIndexBy(
    $contentful_id: String
    $node_locale: String
  ) {
    allContentfulIndex(
      filter: { node_locale: { eq: $node_locale } }
    ) {
      nodes {
        node_locale
        hero {
          title
          subtitle
          image {
            fluid {
              src
            }
          }
          video
        }
        seo {
          title
          description {
            description
          }
        }
        summary {
          title
          description {
            description
          }
          image {
            fluid {
              src
            }
          }
          nested {
            ... on ContentfulArchive {
              relativePath
              hero {
                title
                subtitle
                image {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
        logos {
          title
          fluid {
            src
          }
          description
        }
        callToAction {
          title
          description {
            description
          }
          buttons {
            label
            relativePath
          }
        }
        funFacts {
          title
          description
          colour
          graphic {
            fluid {
              src
            }
          }
        }
      }
    }

    hreflang: allContentfulIndex(
      filter: {
        contentful_id: { eq: $contentful_id }
        node_locale: { ne: $node_locale }
      }
    ) {
      nodes {
        node_locale
      }
    }
  }
`

export default withGlobals(Index)
